import * as Sentry from "@sentry/react"
import robotDanceGif from "./assets/robot-dance.gif"
import {Outlet} from "react-router-dom"
import {ErrorScreen} from "./screens/ErrorScreen.tsx"

export function Layout() {
  return (
    // This should stay at the top level of the layout so we catch all errors.
    // See RouterError.tsx for why we don't rely on that.
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      {/* Not prefetching error.gif b/c it's pretty big */}
      <link rel="prefetch" href={robotDanceGif} />
      <Outlet />
    </Sentry.ErrorBoundary>
  )
}
