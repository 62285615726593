import {isHost, Bot as PlayroomBot} from "playroomkit"
import * as R from "remeda"
import {generateImageForGameWithTemplate, noResponseText, submitPrompt} from "./game"
import {getBots} from "./players"
import {rpc} from "./client"

export class Bot extends PlayroomBot {}

export async function generateBotSubmissions(round: number, theme: string) {
  const bots = getBots()
  if (bots.length === 0) return

  let prompts: string[]
  try {
    prompts = (await rpc.generatePrompt.mutate({theme, numPrompts: bots.length})).prompts
  } catch (err) {
    console.error(err)
    prompts = bots.map((bot) => noResponseText(bot))
  }

  // If we somehow dropped out and rejoined as a non-host while waiting for the response,
  // bail out now (we could in theory still set the result as a non-host but PlayerAtom won't
  // let a non-host set another player's state, and I'd prefer to keep that invariant.)
  if (!isHost()) return
  R.zipWith(bots, prompts, (bot, prompt) => {
    prompt = prompt.replace(/^Create a/, "A")
    const image = generateImageForGameWithTemplate({
      model: "flux/schnell",
      size: "square_hd",
      completion: prompt,
      template: theme,
      isPromptGenerated: true,
      round,
      playerName: bot.getProfile().name,
    })
    submitPrompt(bot, round, prompt, image)
  })
}
