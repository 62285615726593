// export function mapToMap<T, K, V>(iter: Iterable<T>, mapper: (value: T) => [K, V]): Map<K, V> {
//   const result: Map<K, V> = new Map();
//   for (const item of iter) {
//     const [k, v] = mapper(item);
//     result.set(k, v);
//   }
//   return result;
// }

import {NoInfer} from "./types"

interface Gettable<K, V> {
  get: (key: K) => V | undefined
}
interface Settable<K, V> {
  set: (key: K, val: V) => void
}

export function getOrSet<K, V>(
  map: Gettable<K, V> & Settable<K, V>,
  key: K,
  computeVal: () => V,
): V {
  let val = map.get(key)
  if (val === undefined) {
    val = computeVal()
    map.set(key, val)
  }
  return val
}

export function getOrSetVal<K, V>(map: Gettable<K, V> & Settable<K, V>, key: K, defaultVal: V): V {
  let val = map.get(key)
  if (val === undefined) {
    val = defaultVal
    map.set(key, val)
  }
  return val
}

export function mustGet<K, V>(gettable: Gettable<K, V>, key: K): V {
  const val = gettable.get(key)
  if (val === undefined) {
    throw new Error(`Key "${key}" does not exist`)
  }
  return val
}

export function mustProp<K extends PropertyKey, V>(obj: Record<K, V>, key: NoInfer<K>) {
  const val = obj[key]
  if (val === undefined) {
    throw new Error(`Key "${String(key)}" does not exist`)
  }
  return val
}

export function must<T>(val: T | undefined): T {
  if (val === undefined) {
    throw new Error("Value is undefined")
  }
  return val
}

export function mustDelete<K>(deletable: {delete: (key: K) => boolean}, key: K): void {
  if (!deletable.delete(key)) {
    throw new Error(`Unexpected error deleting key "${key}"`)
  }
}

export function _throw(err: string | Error): never {
  if (typeof err === "string") err = new Error(err)
  throw err
}

export function indexByProp<K extends keyof T, T extends Record<K, unknown>>(items: T[], key: K) {
  const map = new Map<T[K], T>()
  for (const item of items) {
    map.set(item[key], item)
  }
  return map
}

export function exactKeys<T extends object>(obj: T) {
  return Object.keys(obj) as Extract<keyof T, string>[]
}

export function exactEntries<T extends object>(obj: T) {
  return Object.entries(obj) as [Extract<keyof T, string>, T[keyof T]][]
}

export function arrayify<T>(val: T | T[]): T[] {
  return Array.isArray(val) ? val : [val]
}

export function capitalize(val: string) {
  return val && val[0]!.toUpperCase() + val.slice(1)
}

export function tryParseJson(text: string): unknown {
  try {
    return JSON.parse(text)
  } catch {
    return undefined
  }
}

export function ordinalName(rank: number) {
  const suffix = rank === 1 ? "st" : rank === 2 ? "nd" : rank === 3 ? "rd" : "th"
  return rank + suffix
}

// There are many use cases for this, but for React in particular: if a react component is the only
// thing handing a promise's rejection (e.g. by calling `use`), you probably want to call
// ignoreUnhandledRejection on the promise and do any logging/etc of the error elsewhere -- in
// concurrent mode, the component that consumes it might not render before the rejection becomes
// unhandled. And if you're generating the promise inside a component (even with useMemo), strict
// mode will result in an extra promise that will become unhandled.
export function ignoreUnhandledRejection<T extends Promise<any>>(promise: T): T {
  // Intentionally not returning this. We attach a handler so it doesn't trigger an
  // unhandledRejection event, but return the original promise (with its rejected value) unaltered.
  promise.catch(() => {})
  return promise
}

export function makeFactory<T, Args extends any[]>(klass: {new (...args: Args): T}) {
  return (...args: Args) => {
    return new klass(...args)
  }
}

export function proxyToGetter<T extends object>(getter: () => T): T {
  return new Proxy(
    {},
    {
      ownKeys: () => Reflect.ownKeys(getter()),
      getOwnPropertyDescriptor: (_, ...args) => Reflect.getOwnPropertyDescriptor(getter(), ...args),
      get: (_, property) => Reflect.get(getter(), property),
      set: (_, property, value) => Reflect.set(getter(), property, value),
    },
  ) as T
}

export const MAX_INT = 2147483647
export const MAX_UINT = 4294967295
