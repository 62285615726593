import {PlayerState, getState, isHost, myPlayer, setState} from "playroomkit"
import * as play from "playroomkit"

//////// Improve playroomkit types ////////
type SetStateFunction<T> = (value: T, reliable: boolean) => void
export type MultiplayerStateHookResult<T = unknown> = [T, SetStateFunction<T>]

const usePlayerState = play.usePlayerState as <T>(
  player: play.PlayerState,
  key: string,
  defaultValue: T,
) => MultiplayerStateHookResult<T>

const useMultiplayerState = play.useMultiplayerState as <T>(
  key: string,
  defaultValue: T,
) => MultiplayerStateHookResult<T>
//////// End improve playroomkit types ////////

import.meta.hot?.on("vite:beforeUpdate", () => {
  gameAtomNames.clear()
  playerAtomNames.clear()
})

function checkPlayroomBug(value: unknown) {
  if (value === undefined) {
    console.warn(
      "WARNING! Setting value to undefined may result in it being null instead due to playroom bug",
    )
  }
}

const gameAtomNames = new Set<string>()
class GameAtom<T> {
  constructor(
    readonly key: string,
    readonly defaultValue: T,
  ) {
    if (gameAtomNames.has(key)) {
      throw new Error(`GameAtom with key "${key}" already exists`)
    }
    gameAtomNames.add(key)
  }
  get(): T {
    const state = getState(this.key) as T | undefined
    return state === undefined ? this.defaultValue : state
  }
  mustGet(): Exclude<T, undefined> {
    const state = this.get()
    if (state === undefined) throw Error(`GameAtom "${this.key}" is undefined`)
    return state as Exclude<T, undefined>
  }
  hostSet(value: T) {
    // console.log("hostSet", {key: this.key, value})
    checkPlayroomBug(value)
    if (!isHost()) {
      const player = myPlayer()
      throw new Error(
        `hostSet called by non-host. id=${player.id} name=${player.getProfile().name}`,
      )
    }
    return setState(this.key, value, true)
  }
  anySet(value: T) {
    // console.log("anySet", {key: this.key, value})
    checkPlayroomBug(value)
    return setState(this.key, value, true)
  }
}
export type {GameAtom}
export function gameAtom<T = undefined>(key: string): GameAtom<T | undefined>
export function gameAtom<T>(key: string, defaultValue: T): GameAtom<T>
export function gameAtom<T>(key: string, defaultValue?: T) {
  return new GameAtom(key, defaultValue)
}

const playerAtomNames = new Set<string>()
class PlayerAtom<T> {
  constructor(
    readonly key: string,
    readonly defaultValue: T,
  ) {
    if (playerAtomNames.has(key)) {
      throw new Error(`PlayerAtom with key "${key}" already exists`)
    }
    playerAtomNames.add(key)
  }
  get(player: PlayerState): T {
    const state = player.getState(this.key) as T | undefined
    return state === undefined ? this.defaultValue : state
  }
  getMine(): T {
    return this.get(myPlayer())
  }
  setMine(value: T) {
    checkPlayroomBug(value)
    myPlayer().setState(this.key, value, true)
  }
  set(player: PlayerState, value: T) {
    checkPlayroomBug(value)
    if (!isHost() && player.id !== myPlayer().id) {
      throw Error(
        `non-host is not allowed to set another player's state. myPlayer=${myPlayer().id} player=${
          player.id
        }`,
      )
    }
    player.setState(this.key, value, true)
  }
}
export type {PlayerAtom}
export function playerAtom<T = undefined>(key: string): PlayerAtom<T | undefined>
export function playerAtom<T>(key: string, defaultValue: T): PlayerAtom<T>
export function playerAtom<T>(key: string, defaultValue?: T) {
  return new PlayerAtom(key, defaultValue)
}

export function gameRoundAtom<T>(key: string): (round: number) => GameAtom<T | undefined>
export function gameRoundAtom<T>(key: string, initialValue: T): (round: number) => GameAtom<T>
export function gameRoundAtom<T>(key: string, initialValue?: T) {
  return atomFamily((round: number) => gameAtom(`${key}-${round}`, initialValue))
}

export function playerRoundAtom<T>(key: string): (round: number) => PlayerAtom<T | undefined>
export function playerRoundAtom<T>(key: string, initialValue: T): (round: number) => PlayerAtom<T>
export function playerRoundAtom<T>(key: string, initialValue?: T) {
  return atomFamily((round: number) => playerAtom(`${key}-${round}`, initialValue))
}

export function atomFamily<Param, AtomType>(initializeAtom: (param: Param) => AtomType) {
  const cache = new Map<Param, AtomType>()
  return (param: Param) => {
    let atom = cache.get(param)
    if (atom === undefined) {
      atom = initializeAtom(param)
      cache.set(param, atom)
    }
    return atom
  }
}

export function useGameAtom<T>(atom: GameAtom<T>) {
  const [state, setState] = useMultiplayerState<T | undefined>(atom.key, undefined)
  return [
    state === undefined ? atom.defaultValue : state,
    (value: T) => setState(value, true),
  ] as const
}

export function usePlayerAtom<T>(player: PlayerState, atom: PlayerAtom<T>) {
  const [state, setState] = usePlayerState<T | undefined>(player, atom.key, undefined)
  return [
    state === undefined ? atom.defaultValue : state,
    (value: T) => setState(value, true),
  ] as const
}

export function useMyPlayerAtom<T>(atom: PlayerAtom<T>) {
  return usePlayerAtom(myPlayer(), atom)
}

declare module "playroomkit" {
  /** @deprecated Use GameAtom#get instead */
  function getState(key: string): any
  /** @deprecated Use GameAtom#set instead */
  function setState(key: string, value: any, reliable?: boolean): void
  /** @deprecated Use useGameAtom instead */
  function useMultiplayerState(key: string, defaultValue: any): MultiplayerStateHookResult
  /** @deprecated Use usePlayerAtom instead */
  function usePlayerState(
    player: PlayerState,
    key: string,
    defaultValue: any,
  ): MultiplayerStateHookResult

  interface PlayerState {
    /** @deprecated Use PlayerAtom#set instead */
    setState: (key: string, value: any, reliable?: boolean) => void
    /** @deprecated Use PlayerAtom#get instead */
    getState: (key: string) => any
  }
}
