// https://www.makeuseof.com/ai-art-prompt-ideas/
// https://www.greataiprompts.com/imageprompt/image-prompts-for-bing-image-creator/
// https://old.reddit.com/r/weirddalle/
// https://old.reddit.com/r/aiArt/
// https://old.reddit.com/r/aigeneratedmemes/

// TODO: Rename to `prompts`
export const themes = [
  "A stained-glass version of ___",
  "A clown version of ___",
  "A nerdy version of ___",
  "An extremely dorky ___",
  "An extremely crazy ___",
  "An extremely weird ___",
  "An extremely silly ___",
  "A deranged ___",
  "A steampunk ___",
  "A whimsical ___",
  "A new mythical creature: ___",
  "A tattoo of ___",
  "A 100 dollar bill featuring ___",
  "A silver dollar featuring ___",
  "A gold coin featuring ___",
  "A futuristic ___",
  "___ in the year 3000",
  "___ on steroids",
  "___ in outer space",
  "___ as a topiary",
  "___ as a Klingon",
  "___ as a stuntman",
  "___ as a king",
  "___ as a queen",
  "___ as a court jester",
  "___ as a postage stamp",
  "___ as a plastic game piece",
  "___ as a metal game piece",
  "___ as a Taco Bell logo",
  "___ as a Wendy's logo",
  "___ as a jack-in-the-box",
  "___ as a toaster",
  "___ as a toilet",
  "___ as an alien",
  "___ as a zombie",
  "___ as a cactus creature",
  "___ as a Pokemon creature",
  "___ as a rose creature",
  "___ as a sunflower creature",
  "___ as a slime creature",
  "___ as a paperclip creature",
  "___ as a playdough creature",
  "___ as a spider creature",
  "___ as a sun creature",
  "___ as a lightbulb creature",
  "___ as a space creature",
  "___ as a space creature",
  "___ as a sewer creature",
  "___ as a lava creature",
  "___ as a minion",
  "___ as a magician",
  "___ as a pirate",
  "___ as a witch",
  "___ as The Wicked Witch",
  "___ as the devil",
  "___ as Beelzebub",
  "___ as an angel",
  "___ as an insect",
  "___ as the president",
  "___ as a priest",
  "___ as the pope",
  "___ as a cyborg",
  "___ as a mermaid",
  "___ as Buddha",
  "___ as a ghost",
  "___ as a Reggae singer",
  "___ as Barbie",
  "___ Barbie",
  "___ as Humpty Dumpty",
  "___ as The Mad Hatter",
  "___ as The Joker",
  "___ as The Tin Man",
  "___ as a scarecrow",
  "___ as a sorcerer",
  "___ as origami",
  "___ as Banksy art",
  "___ as Michael Jackson",
  "___ as Ronald McDonald",
  "___ as the Hamburgler",
  "___ as the cookie monster",
  "___ as Oscar the Grouch",
  "___ as Kermit the Frog",
  "___ as Colonel Sanders",
  "___ as Willy Wonka",
  "___ as The Grinch",
  "___ as Santa Clause",
  "___ as Superman",
  "___ as a superhero",
  "___ as a paparazzi",
  "___ as a coal miner",
  "___ as a dancer",
  "___ as a russian dancer",
  "___ as a ballerina",
  "___ as a nutcracker",
  "___ as a groom",
  "___ as a bride",
  "___ as a janitor",
  "___ as a construction worker",
  "___ as a flight attendant",
  "___ as an insane person",
  "___ as an singer",
  "___ as an drummer",
  "___ as a conductor",
  "___ as a police officer",
  "___ as a fire fighter",
  "___ as a pilot",
  "___ as a boat captain",
  "___ as a DJ",
  "___ as a sumo wrestler",
  "___ as a wrestler",
  "___ as a gymnast",
  "___ as a contortionist",
  "___ as an acrobat",
  "___ as a boxer",
  "___ as a fly",
  "___ as a pig",
  "___ on a rollercoaster",
  "An alien riding a ___",
  "___ riding a bull",
  "___ riding a crocodile",
  "___ riding a giraffe",
  "___ riding a shark",
  "___ riding a dragon",
  "___ riding a monster",
  "___ riding an alien creature",
  "___ laughing maniacally",
  "___ doing a BMX stunt",
  "___ doing a magic trick",
  "___ skydiving",
  "___ skateboarding",
  "___ snowboarding",
  "___ ski jumping",
  "___ surfing on a giant wave",
  "___ summoning superpowers",
  "___ casting a spell",
  "___ ice skating",
  "___ ice dancing",
  "___ figure skating",
  "___ doing karate",
  "___ doing yoga",
  "___ doing the splits",
  "___ reaching enlightenment",
  "___ as a playing card",
  "___ as a chess piece",
  "___ playing Monopoly",
  "___ playing chess",
  "___ playing poker",
  "___ playing baseball",
  "___ playing football",
  "___ playing soccer",
  "___ playing basketball",
  "___ playing hockey",
  "___ playing tennis",
  "___ playing ping pong",
  "___ playing volleyball",
  "___ running a marathon",
  "___ doing a backflip",
  "___ doing a cartwheel",
  "___ crowdsurfing",
  "___ lifting weights",
  "___ swimming",
  "___ synchronized swimming",
  "___ roller skating",
  "___ in the olympics",
  "___ in the army",
  "___ in the navy",
  "___ in the marines",
  "___ in the air force",

  "___ in a church choir",
  "___ in a choir",
  "___ in a bobsled",
  "___ on an alien planet",

  "___ dancing",
  "___ dancing together",
  "A dancing ___",
  "A flying ___",
  "A melting ___",
  "A frozen ___",
  "A goth ___",
  "An emo ___",
  "An obese ___",
  "An extreme ___",
  "An extremely shocked ___",
  "An extremely angry ___",
  "An extremely happy ___",
  "An extremely sad ___",
  "An extremely ugly ___",
  "An extremely skinny ___",
  "An extremely spicy ___",
  "A paper quilling of ___",
  "A Roman statue of ___",
  "A baby ___",
  "Baby ___",
  "The answer to life, the universe, and everything: ___",
  "A hybrid of a strawberry and ___",
  "A hybrid of a banana and ___",
  "A hybrid of a pineapple and ___",
  "A hybrid of a penguin and ___",
  "A hybrid of a bumble bee and ___",
  "Honey Bunches of ___",
  "A box of cereal featuring ___",
  "A new cereal: ___",
  "A new dinner recipe: ___",
  "A new dessert: ___",
  "A new animal: ___",
  "A new plant: ___",
  "A new sea creature: ___",
  "A skeleton ___",
  "Rosie the Riveter, but ___",
  "The Marshmallow Man, but ___",
  "mr potato head, but he's ___",
  "American Gothic, but the man is ___",
  "A new Van Gogh painting: Starry Night at ___",
  "___ by Vincent Van Gogh",
  "___ by Salvador Dali",
  "___ by M.C. Escher",
  "___ under the sea",
  "A face that combines the features of ___",
  "Snoop Dogg ___",
  "Beyonce ___",
  "Jay Z ___",
  "Osama Bin Laden ___",
  "Donald Trump ___",
  "Joe Biden ___",
  "Bill Clinton ___",
  "Barack Obama ___",
  "Taylor Swift ___",
  "Vladimir Putin ___",
  "King Jong Un ___",
  "Hiter ___",
  "Baby Shark ___",
  "Mr. Beast ___",
  "Jimmy Fallon ___",
  "The Mona Lisa ___",
  "Shohei Ohtani ___",
  "Billie Eilish ___",
  "Bill Clinton ___",
  "Ken Jennings ___",
  "Mike Tyson ___",
  "Elon Musk ___",
  "Mark Zuckerberg ___",
  "Bill Gates ___",
  "Jeff Bezos ___",
  "Abraham Lincoln ___",
  "George Washington ___",
  "Albert Einstein ___",
  "King Tut ___",
  "Genghis Khan ___",
  "Captain Picard ___",
  "Gollum ___",
  "Luke Skywalker ___",
  "Darth Vader ___",
  "Chewbacca ___",
  "Jabba the Hut ___",
  "Yoda ___",
  "A new superhero: __",
  "A new supervillain: __",
  "Half robot, half ___",
  "Half monster, half ___",
  "Half cat, half ___",
  "Half dog, half ___",
  "Half skeleton, half ___",
  "Half horse, half ___",
  "Half octopus, half ___",
  "Half shark, half ___",
  "Half shrimp, half ___",
  "Half dolphin, half ___",
  "Half doctor, half ___",
  "Half snowman, half ___",
  "A new invention: the ___",
  "A butterfly with wings of ___",
  "A monster with ___",
  "A ___ monster",
  "A ___ creature",
  "A robot ___",
  "A robot made of ___",
  "An alien ___",
  "A martian ___",
  "An alien with ___",
  "An alien made of ___",
  "A tornado made of ___",
  "A monster made of ___",
  "A shark made of ___",
  "A shrimp made of ___",
  "A lobster made of ___",
  "An octopus made of ___",
  "A cat made of ___",
  "A dog made of ___",
  "A mouse made of ___",
  "A dragon made of ___",
  "A snake made of ___",
  "A house made of ___",
  "An igloo full of ___",
  "A swimming pool full of ___",
  "A car made of ___",
  "A shoe made of ___",
  "A black hole made of ___",
  "The universe made of ___",
  "A galaxy made of ___",
  "A planet made of ___",
  "A toilet made of ___",
  "A toilet ___",
  "An iphone made of ___",
  "An octopus made of ___",
  "A chicken made of ___",
  "A tree made of ___",
  "An ice sculpture of ___",
  "A tank made of ___",
  "An airplane made of ___",
  "A bomb made of ___",
  "A bowl of ___ ice cream",
  "A fire-breathing ___",
  "An ice-breathing ___",
  "___ with a trypophobia texture",
  "___ on a cake",
  "___ on a cupcake",
  "___ on a cookie",
  "___ made of silverware",
  "___ made of whipped cream",
  "___ made of cake",
  "___ made of Jello",
  "___ made of cabbage",
  "___ made of lettuce",
  "___ made of celery",
  "___ made of rocks",
  "___ made of brick",
  "___ made of chopsticks",
  "___ made of Jenga blocks",
  "___ made of dollar bills",
  "___ made of orange juice",
  "___ made of coins",
  "___ made of lava",
  "___ made of bees",
  "___ made of pure energy",
  "___ made of love",
  "___ made of toilet paper",
  "___ made of mirrors",
  "___ made of lasers",
  "___ made of chocolate",
  "___ made of candy",
  "___ made of red licorice",
  "___ made of silver",
  "___ made of gold",
  "___ made of fire",
  "___ made of fireworks",
  "___ made of ice",
  "___ made of water",
  "___ made of sand",
  "___ made of snow",
  "___ made of pennies",
  "___ made of sequins",
  "___ made of rainbows",
  "___ made of sunshine",
  "___ made of clouds",
  "___ made of lightning",
  "___ made of a black hole",
  "___ made of wood",
  "___ made of marble",
  "___ made of popcorn",
  "___ made of fried chicken",
  "___ made of hummus",
  "___ made of ketchup and mustard",
  "___ made of peanut butter",
  "___ made of jelly",
  "___ made of peanut butter and jelly",
  "___ made of carrots",
  "___ made of peas",
  "___ made of peas and carrots",
  "___ made of corn",
  "___ made of wheat",
  "___ made of jalapenos",
  "___ made of bell peppers",
  "___ made of green bean pods",
  "___ made of macaroni and cheese",
  "___ made of alfalfa sprouts",
  "___ made of bean sprouts",
  "___ made of brussels sprouts",
  "___ made of broccoli",
  "___ made of cauliflower",
  "___ made of waffles",
  "___ made of bread",
  "___ made of marshmallows",
  "___ made of christmas lights",
  "___ made of yarn",
  "___ made of felt",
  "___ made of strawberries",
  "___ made of bananas",
  "___ made of pumpkins",
  "___ made of pickles",
  "___ made of pineapple",
  "___ made of spaghetti",
  "___ made of roses",
  "A geode featuring ___",
  "A constellation of ___",
  "A patriotic ___",
  "A purple ___",
  "A pink ___",
  "A neon ___",
  "___ as a neon sign",
  "A transparent ___",
  "A bioluminescent ___",
  "An albino ___",
  "A magic ___",
  "A pixel art ___",
  "A caricature drawing of ___",
  "A 5-year-old's simple crayon drawing of ___",
  "___ on a birthday card",
  "___ as a gameshow host",
  "___ as a hobbit",
  "___ as a redneck",
  "___ as a cowboy",
  "___ as an outlaw",
  "___ as a caveman",
  "___ as a Simpsons character",
  "___ as a messy eater",
  "___ in the 1800's",
  "___ in the 50's",
  "___ in the 60's",
  "___ in the 70's",
  "___ in the 80's",
  "___ in the 90's",
  "___ in heaven",
  "___ in hell",
  "Napolean Dynamite with ___",
  "___ in Star Trek",
  "___ in Star Wars",
  "___ Versus the Volcano",
  "___ in Breaking Bad",
  "A new Disney movie: ___",
  "Harry Potter and the ___",
  "The Princess and the ___",
  "An amigurumi ___",
  "___ made of legos",
  "A new fairy tale: ___",
  "Snow White and the Seven ___",
  "An anthropomorphic ___",
  "A psychadelic ___",
  "A tie-dyed ___",
  "An evil ___",
  "A mutant ___",
  "A radioactive ___",
  "Willy Wonka and the ___",
  "___ and the Amazing Technicolor Dreamcoat",
  "___ in Alice's Wonderland",
  "___ and the Beanstalk",
  "___ ascending to heaven",
  "Casper the ___ ghost",
  "___ On A Plane",
  // https://old.reddit.com/r/weirddalle/comments/1ajhue1/presidential_election_campaign_billboard/
  "Presidential campaign billboard for ___",
  "___ eating ice cream",
  "A mushroom growing out of ___",
  "A ___ with a human face",
  "A ___ with a face",
  "A ___ with arms and legs",
  "Mario and Luigi ___",
  "Super Mario ___",
  "___ dressed like Guy Fieri",
  "___ dressed as Snoop Dogg",
  "___ dressed as a rapper",
  "___ dressed as a country singer",
  "A cow wearing ___",
  "A snail wearing ___",
  "A penguin wearing ___",
  "A dinosaur wearing ___",
  "A blinged-out ___",
  "___ with a tree growing out of their head",
  "___ with a mushroom growing out of their head",
  "___ with snakes growing out of their head",
  "___ with mad cow disease",
  "___ with tattoos",
  "___ with pink hair",
  "___ with green hair",
  "___ with long hair",
  "___ with a mohawk",
  "___ with spiked hair",
  "___ with curly hair",
  "___ with an afro",
  "___ with a mullet",
  "___ with dreadlocks",
  "___ with a huge brain",
  "___ wearing a funny wig",
  "___ wearing a silly hat",
  "___ wearing silly glasses",
  "___ wearing silly makeup",
  "___ wearing a funny disguise",
  "___ wearing a funny costume",
  "___ with wings",
  "___ with horns",
  "___ with a mustache",
  "___ with a beard",
  "___ in The Matrix",
  "___ in a bubble",
  "___ in a bottle",
  "___ in a snowglobe",
  "___ as a head in a jar",
  "___ in a winter wonderland",
  "___ in an autumn wonderland",
  "___ on summer vacation",
  "A sunset featuring ___",
  "Springtime for ___",
  "___ in Stranger Things",
  "___ in a dream",
  "___ in a nightmare",
  "___ in paradise",
  "___ in the sewer",
  "___ in Saved By The Bell",
  "___ on a Lite Brite",
  "A wall of graffiti featuring ___",
  "Nonsense words: ___",
  "A ___ Wizzlewazzle",
  "A ___ Snizzlepuff",
  "A ___ Blubberflop",
  "A ___ Quagglewump",
  "A black ___",
  "A white ___",
  "A Mexican ___",
  "A Russian ___",
  "A Swiss ___",
  "A Japanese ___",
  "A Chinese ___",
  "A Korean ___",
  "A Hawaiian ___",
  "A Vietnamese ___",
  "A European ___",
  "A Canadian ___",
  "A Caribbean ___",
  "A Native American ___",
  "An Egyptian ___",
  "A Brazilian ___",
  "An Asian ___",
  "An Indian ___",
  "A middle-eastern ___",
  "A gender reveal using ___",
  "___ in the witness protection program",
  "___ turned into a necklace",
  "___ making a funny face",
  "___ shaking hands",
  "A cartoon of ___ making a funny face",
  "A cartoon of ___ making a silly face",
  "___ sticking their tongue out",
  "___ carved into a glowing jack-o'-lantern",
  "___ painted on an egg",
  "A ___ hatching out of an egg",
  "A ___ bomb",
  "The Devil Wears ___",
  "The Old Man and the ___",
  "Old MacDonald had a ___",
  "Mary had a little ___",
  "Row, row, row your ___",
  "Jack and the ___ stalk",
  "Jack and the bean ___",
  "___ and the beanstalk",
  "The jolly green ___",
  "Goldilocks and the three ___",
  "Aladdin and the magic ___",
  "Aladdin and the flying ___",
  "The goose that lays the ___ eggs",
  "Cinderella's ___ slipper",
  "A gingerbread ___",
  "Twinkle, twinkle, little ___",
  "Baa baa black sheep, have you any ___?",
  "___ with feathers",
  "___ in a shell",
  "___ made of shells",
  "___ in a toilet",
  "___ on roller skates",
  "___ on roller blades",
  "___ on fire",
  "___ jumping on a trampoline",
  "___ in a silly selfie",
  "A goofy ___",
  "___ in a funhouse",
  "___ in a funhouse mirror",
  "___ in a funny mirror",
  "___ in a wormhole",
  "___ having fun",
  "___ looking cool",
  "___ as a biker",
  "___ as a cheerleader",
  "___ breakdancing",
  "___ doing the worm",
  "___ doing the hustle",
  "___ doing the Macarena",
  "___ doing the Macarena",
  "___ disco dancing",
  "___ at a rave",
  "___ making a duck face",
  "___ with a duck bill",
  "___ doing a handstand",
  "___ doing a headstand",
  "___ doing a headstand",
  "___ doing the splits",
  "___ doing yoga",
  "___ doing a crab walk",
  "___ partying",
  "A very disappointed ___",
  "A ___ leprechaun",
  "A leprechaun ___",
  "A flirting ___",
  "A laid-back ___",
  "A hypnotic ___",
  "___ with funny hair",
  "___ with funny eyes",
  "___ with funny ears",
  "___ with funny eyebrows",
  "___ with funny teeth",
  "___ with a funny mouth",
  "___ with a funny head",
]

// Good prompts that the current model doesn't produce good results for:
// ___ wearing a Grouch Marx glasses and mustache disguise
// ___ with googly eyes and a mustache
// ___ with hair made of ___
// A cat that looks like ___
// ___ with a cat face
// ___ with a skeleton body
// ___ as the Kool Aid man
// ___ as a Blue Man Group member
// Sonic the ___
// ___ as a stuffed toy
// ___ as a ventriloquist doll
// ___ as a muppet
// A river made of ___
// A Kafkaesque ___
// The image of ___ on a piece of burnt toast
// Taylor Swift with eyes made of ___
// A cloud that looks like ___
// A hologram of ___
// ___ made of pepperoni
// A prison mugshot of ___
// ___ in a police lineup
// A car with wheels made of ____
// ___ as a balloon animal
// ___ as a bumble bee
// ___ as a cyclops
// ___ with awooga eyes
// ___ as a turtle
// ___ as a snail
// ___ as a monkey
// ___ as a conehead
// ___ as an egghead
// ___ as a giant head on a tiny body
// ___ with vampire teeth
// ___ with funny fake teeth
// ___ sticking out his tongue
// ___ with lots of pimples
// ___ with a pig nose
// ___ with a tail
// ___ with antennae (works only sometimes)
