import errorGif from "../assets/error.gif"
import {twButton, twRootLayout} from "../components/styles"
import {cn} from "../utils/css"

export function ErrorScreen() {
  return (
    <div className="absolute inset-0 bg-black text-center">
      <div className={cn(twRootLayout, "mx-auto")}>
        <img className="inline-block" src={errorGif} />
        <div className="mt-1 mb-3 text-white text-lg">
          Welp, something went wrong.
          <br />
          Reloading the page can sometimes fix it.
        </div>
        <button
          className={cn(twButton, "font-semibold text-lg mb-0.5")}
          onClick={() => {
            location.reload()
          }}
        >
          Reload page
        </button>
        <button
          className={cn(twButton, "font-semibold text-lg")}
          onClick={() => {
            location.href = "/"
          }}
        >
          Exit Game
        </button>
      </div>
    </div>
  )
}
