import {HostButton, HostContinueButton} from "../components/components"
import {ordinalName} from "../../shared/utils/builtins"
import {twScreenTitle} from "../components/styles"
import {Scores, isFinalRound, scoresAtom, trackGameStart} from "../game"
import {setRound} from "../game"

import {useMemo} from "react"
import Confetti from "react-confetti"
import {AvatarIcon} from "../AvatarIcon"
import {getPlayer} from "../players"
import {numRounds} from "./interstitials"

type ScoreScreenProps = {
  scores: Scores
  round: number
}
export function ScoreScreen({scores, round}: ScoreScreenProps) {
  const sortedScores = useMemo(() => Object.entries(scores).sort((a, b) => b[1] - a[1]), [scores])
  const twTh = "text-left"
  const twPlace = "w-16"
  const gameOver = isFinalRound(round)
  const winner = getPlayer(sortedScores[0]![0])
  return (
    <div>
      {gameOver ? (
        <div>
          <Confetti recycle={false} numberOfPieces={500} />
          <div className="text-center mb-5">
            <div className="mb-1 font-bold text-lg">Winner!</div>
            <AvatarIcon size={64} player={winner} />
            <div className="font-medium italic">{winner.getProfile().name}</div>
          </div>
        </div>
      ) : (
        <div className={twScreenTitle}>{gameOver ? "Final Scores" : "Scoreboard"}</div>
      )}
      <table className="w-full mb-5">
        <thead>
          <tr>
            <th className={twTh}>Place</th>
            <th className={twTh}>Player</th>
            <th className={twTh}>Score</th>
          </tr>
        </thead>
        <tbody>
          {sortedScores.map(([playerId, score], index) => {
            const player = getPlayer(playerId)
            return (
              <tr key={playerId}>
                <td className={twPlace}>{ordinalName(index + 1)}</td>
                <td className="flex items-center gap-1">
                  <AvatarIcon size={24} player={player} />
                  <div>{player.getProfile().name}</div>
                </td>
                <td>{score}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {!gameOver ? (
        <HostContinueButton
          onClick={() => {
            setRound(round + 1)
          }}
        />
      ) : (
        <div>
          <HostButton
            label="Play Again (same players)"
            onClick={() => {
              console.log("Playing again (same players)")
              // NB: not using resetStates() or setting stuff to undefined due to playroom bug
              // that results in values being null instead of undefined. Which is why we're also
              // not setting the round back to 0, for now we fake it by modding the round value
              // when it gets displayed.
              scoresAtom.hostSet({})
              const nextRound = round + 1
              setRound(nextRound)
              trackGameStart({rematchNum: nextRound / numRounds})
            }}
          />
          <HostButton
            label="Exit to home screen"
            onClick={() => {
              location.href = "/"
            }}
          />
        </div>
      )}
    </div>
  )
}
