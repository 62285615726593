import * as R from "remeda"
import {WaitingForOthers} from "../components/components"
import {SubmissionCore} from "../components/Submission"
import {generateBotSubmissions} from "../bot"
import {
  isDevMode,
  imageAtom,
  pastThemesAtom,
  submissionAtom,
  anySetScreen,
  loadImage,
  noResponseText,
  generateImageForGame,
  generateImageForGameWithTemplate,
} from "../game"
import {GeneratedImage, fillPromptTemplate} from "../client"
import {twButton} from "../components/styles"
import {myPlayer, isHost} from "playroomkit"
import {useRef, useState, useEffect} from "react"
import {getBots} from "../players"
import {onEachPlayerAtomTruthy, hostOnAllPlayersAtomTruthy} from "../utils/playroom"
import {useOnce, useTimeLeft} from "../react/hooks"
import {hostSetInterstitial} from "./interstitials"
import {gameRoundAtom, useMyPlayerAtom} from "../atoms"
import {submitPrompt} from "../game"
import {cn} from "../utils/css"

const timeLeftAtom = gameRoundAtom("timeLeft", isDevMode() ? 9999 : 55)

type Preview = {
  text: string
  image: Promise<GeneratedImage>
  key: number
}

export function WriteScreen({theme, round}: {theme: string; round: number}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [submission] = useMyPlayerAtom(submissionAtom(round))
  const [previews, setPreviews] = useState<Preview[]>([])

  useOnce(() => {
    if (isHost()) generateBotSubmissions(round, theme)
  })

  useEffect(() => {
    return hostOnAllPlayersAtomTruthy(submissionAtom(round), {bots: true}, (playerStates) => {
      hostSetInterstitial("showcase", "ShowcaseScreen", {
        submittedIds: R.shuffle(playerStates).map((ps) => ps.player.id),
        theme,
        round,
      })
    })
  }, [theme, round])

  useEffect(() => {
    return onEachPlayerAtomTruthy(imageAtom(round), {bots: true}, (_player, image) => {
      // Preload images. Runs even after unmount.
      if (!("url" in image)) return
      loadImage(image.url)
    })
  }, [round])

  const [timerStart] = useState(() => timeLeftAtom(round).get())
  const timeLeft = useTimeLeft(timerStart, 1000, (timeLeft) => {
    const me = myPlayer()
    if (isHost()) {
      // Used above to initialize the timer for late joiners
      timeLeftAtom(round).hostSet(timeLeft)
      if (timeLeft <= 0) {
        for (const bot of getBots()) {
          if (submissionAtom(round).get(bot) == null) {
            // Must've been an error calling the API, or the original host dropped out before it
            // got the response.
            // TODO: submit "bot didn't respond" instead of kicking
            bot.kick()
          }
        }
      }
    }
    if (timeLeft <= 0 && submission == null) {
      const text = previews[0]?.text || inputRef.current!.value.trim() || noResponseText(me)
      const image =
        previews[0]?.image ??
        generateImageForGameWithTemplate({
          model: "flux/schnell",
          size: "square",
          completion: text,
          template: theme,
          isPromptGenerated: false,
          round,
          playerName: me.getProfile().name,
        })
      submitPrompt(me, round, text, image)
    }
  })

  const doPreviews = async () => {
    const me = myPlayer()
    const text = inputRef.current!.value.trim()
    if (text === "") return
    const {prompt} = await fillPromptTemplate({completion: text, template: theme})
    const variants = [
      ["flux/schnell", "square"],
      ["flux/schnell", "square_hd"],
      ["fast-sdxl", "square_hd"],
    ] as const
    setPreviews(
      variants.map(([model, size], i) => ({
        text,
        image: generateImageForGame({
          model,
          size,
          prompt,
          template: theme,
          enhancePrompt: true,
          isPromptGenerated: false,
          round,
          playerName: me.getProfile().name,
        }),
        key: i,
      })),
    )
  }

  return (
    <div className="text-center">
      <div className="font-bold pb-1">{Math.ceil(timeLeft)}</div>
      <div className="pb-2 text-lg italic">{theme}</div>
      {submission != null ? (
        <WaitingForOthers />
      ) : (
        <div>
          {previews.length === 0 ? (
            <form
              action="#" // Needed for correct "Go" label on iPhone keyboard
              onSubmit={(event) => {
                event.preventDefault()
                doPreviews()
              }}
            >
              <input
                ref={inputRef}
                className="w-full h-[46px] rounded-lg border border-yellow-800 outline-none py-2 px-3 text-lg mb-2"
              />
              <button className={twButton}>Submit</button>
            </form>
          ) : (
            <div>
              <div className="pb-3">
                <div>Tap your favorite image to submit it.</div>
              </div>
              {previews.map((preview) => (
                <SubmissionCore
                  key={preview.key}
                  text={preview.text}
                  image={preview.image}
                  onClick={() => {
                    submitPrompt(myPlayer(), round, preview.text, preview.image)
                  }}
                />
              ))}
            </div>
          )}
          {isDevMode() && (
            <button
              className={cn(twButton, "mt-1 mb-3")}
              onClick={() => {
                anySetScreen("ThemeScreen", {
                  round,
                  themeChooser: myPlayer().id,
                  pastThemes: pastThemesAtom.get(),
                })
              }}
            >
              ThemeScreen
            </button>
          )}
        </div>
      )}
    </div>
  )
}
