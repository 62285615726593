// Adapted from https://www.30secondsofcode.org/react/s/use-hash/
// See also:
// https://github.com/streamich/react-use/blob/master/src/useHash.ts
// https://github.com/heyitsarpit/react-hooks-library/blob/main/packages/core/useLocation/index.ts

import {useCallback, useEffect, useState} from "react"
import {useCommittedRef} from "./hooks"

export function useHash(onChange?: () => void) {
  const [hash, setHash] = useState(() => window.location.hash)
  const onChangeRef = useCommittedRef(onChange)

  const hashChangeHandler = useCallback(() => {
    onChangeRef.current?.()
    setHash(window.location.hash)
  }, [onChangeRef])

  useEffect(() => {
    window.addEventListener("hashchange", hashChangeHandler)
    return () => {
      window.removeEventListener("hashchange", hashChangeHandler)
    }
  }, [hashChangeHandler])

  const updateHash = useCallback(
    (newHash: string) => {
      if (newHash !== hash) window.location.hash = newHash
    },
    [hash],
  )

  return [hash, updateHash] as const
}
