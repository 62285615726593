import {ReactNode} from "react"
import {ScreenName, ScreenProps, hostSetScreen} from "../game"

export type InterstitialProps = {
  title: string
  content: ReactNode
  duration?: number
}
export type InterstitialName = keyof typeof interstitials

const roundInterstitials = {
  round0: {
    title: "Round 1",
    content: "Welcome to AI Party 🎉",
  },
  round1: {
    title: "Round 2",
    content: "",
  },
  round2: {
    title: "Round 3",
    content: "",
  },
  round3: {
    title: "Round 4",
    content: "Final round. Points are doubled.",
  },
}

export const interstitials = {
  ...roundInterstitials,
  showcase: {
    title: "Showcase",
    content: "The host will cycle through the images.",
  },
  vote: {
    title: "Vote",
    content: "Tap an image to cast your vote",
  },
} satisfies Record<string, InterstitialProps>

export const numRounds = Object.keys(roundInterstitials).length

export function hostSetInterstitial<T extends ScreenName>(
  interstitialName: InterstitialName,
  screenName: T,
  props: ScreenProps<T>,
) {
  hostSetScreen("InterstitialScreen", {
    name: interstitialName,
    nextScreenName: screenName as ScreenName,
    nextScreenProps: props,
  })
}
