import "./index.css"
import React from "react"
import {createRoot} from "react-dom/client"
import {PlayerState, myPlayer} from "playroomkit"
import * as Sentry from "@sentry/react"
import {ErrorScreen} from "./screens/ErrorScreen.tsx"
import {screenAtom} from "./game.ts"
import {RouterProvider} from "react-router-dom"
import {router} from "./router.tsx"

console.log("meta.env", import.meta.env)

Sentry.init({
  dsn: "https://a4f4a3067d0c98d88e0d4989578f1b03@o4506538978639872.ingest.sentry.io/4506539068489728",
  integrations: [],
  enabled: !import.meta.env.DEV,
  environment: import.meta.env.MODE,
  beforeSend(event) {
    // This can be undefined even though the types say otherwise
    const player = myPlayer() as PlayerState | undefined
    const screenState = screenAtom.get()
    const screenName = screenState?.[0]
    event.tags ??= {}
    Object.assign(event.tags, {
      screen: screenName,
      // Intentionally setting these separate from Sentry's `user` tag, because these are ephemeral.
      "player.name": player?.getProfile()?.name,
      "player.id": player?.id,
    })
    return event
  },
})

const root = createRoot(document.getElementById("root")!)
root.render(
  <React.StrictMode>
    {/* Errors should rarely reach this since react-router has its own boundary, but it's here as a last backstop */}
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      <RouterProvider router={router} />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)
