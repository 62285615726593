import * as R from "remeda"
import {curPlayersById, getBots, getHumans, initPlayers} from "./players"
import {hostSetInterstitial, numRounds} from "./screens/interstitials"
import {getOrSet} from "../shared/utils/builtins"
import {ScreenName, ScreenProps} from "./App"
import {gameAtom, playerRoundAtom} from "./atoms"
import {PlayerState, insertCoin, isHost} from "playroomkit"
import {PromiseWithStatus} from "./utils/PromiseWithStatus"
import {GeneratedImage, RpcInput, TemplateAndCompletion, fillPromptTemplate, rpc} from "./client"
import {Bot} from "./bot"
import {nanoid} from "nanoid"
export type {ScreenName, ScreenProps}

export type SubmissionInput = {text: string}
export type ImageResult = GeneratedImage | {error: string}
export type Scores = Record<string, number>

const finalRound = numRounds - 1
export function isFinalRound(round: number) {
  return round % numRounds === finalRound
}

// <Atoms>
const pastThemeChoosersAtom = gameAtom<string[]>("pastThemeChoosers", [])
export const pastThemesAtom = gameAtom<string[]>("pastThemes", [])
export const screenAtom = gameAtom<[ScreenName, ScreenProps<ScreenName>]>("screen")
export const gameIdAtom = gameAtom<string>("gameId")
export const submissionAtom = playerRoundAtom<SubmissionInput>("submission")
export const imageAtom = playerRoundAtom<ImageResult>("image")
export const scoresAtom = gameAtom<Scores>("scores", {})
// </Atoms>
export const loadedImageUrls = new Map<string, PromiseWithStatus<string>>()

export function loadImage(url: string) {
  return getOrSet(loadedImageUrls, url, () => {
    const img = new Image()
    return new PromiseWithStatus<string>((resolve, reject) => {
      img.onload = () => {
        resolve(url)
      }
      img.onerror = () => {
        reject(Error("Failed to load image"))
      }
      img.src = url
    }).ignoreUnhandledRejection()
  })
}

export function initGame() {
  // This must be called before insertCoin so that onPlayerJoin callbacks run before initGame()
  initPlayers()
  insertCoin({enableBots: true, reconnectGracePeriod: 10000, botOptions: {botClass: Bot}}).then(
    () => {
      startGame()
    },
  )
}

export function startGame() {
  // screenAtom might already be set if we're rejoining the game as the host
  if (isHost() && screenAtom.get() === undefined) {
    console.log("Initializing round 0")
    gameIdAtom.hostSet(nanoid(12))
    setRound(0)
    trackGameStart({rematchNum: 0})
  }
}

export function trackGameStart(props: {rematchNum: number}) {
  const numPlayers = curPlayersById.size
  const numBots = getBots().length
  const gameId = gameIdAtom.mustGet()
  rpc.startGame.mutate({gameId, numBots, numHumans: numPlayers - numBots, ...props})
}

export function setRound(round: number) {
  const pastThemeChoosers = pastThemeChoosersAtom.get()
  const curHumanIds = getHumans().map((player) => player.id)
  const pool = curHumanIds.filter((id) => !pastThemeChoosers.includes(id))
  const themeChooser = R.sample(pool.length > 0 ? pool : curHumanIds, 1)[0]!
  if (pool.length > 0) {
    pastThemeChoosersAtom.hostSet([...pastThemeChoosers, themeChooser])
  }
  hostSetInterstitial(("round" + (round % numRounds)) as "round0", "ThemeScreen", {
    round,
    themeChooser,
    pastThemes: pastThemesAtom.get(),
  })
}

export function hostSetScreen<T extends ScreenName>(name: T, props: ScreenProps<T>) {
  screenAtom.hostSet([name, props])
}

export function anySetScreen<T extends ScreenName>(name: T, props: ScreenProps<T>) {
  screenAtom.anySet([name, props])
}

export function isDevMode() {
  return !!localStorage.getItem("devMode")
}
export function randomSeed() {
  return Math.floor(Math.random() * 4294967296)
}

export async function submitPrompt(
  player: PlayerState,
  round: number,
  text: string,
  imagePromise: Promise<GeneratedImage>,
) {
  submissionAtom(round).set(player, {text})
  imagePromise.then(
    (image): void => {
      imageAtom(round).set(player, image)
    },
    (err: unknown) => {
      imageAtom(round).set(player, {error: `${err}`})
    },
  )
}

export function noResponseText(player: PlayerState) {
  return `[${player.getProfile().name} did not respond]`
}

type GenerateImageForGameProps = {isPromptGenerated: boolean; round: number; playerName: string}

export async function generateImageForGameWithTemplate(
  props: TemplateAndCompletion &
    Omit<RpcInput["generateImage"], "prompt" | "template"> &
    GenerateImageForGameProps,
): Promise<GeneratedImage> {
  const {prompt} = await fillPromptTemplate(props)
  return generateImageForGame({...props, prompt, template: props.template})
}

export async function generateImageForGame(
  props: RpcInput["generateImage"] & GenerateImageForGameProps,
): Promise<GeneratedImage> {
  return await rpc.generateImage.mutate({
    ...props,
    gameId: gameIdAtom.get(),
    gameRound: props.round,
  })
}
