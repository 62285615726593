// Adapted from https://github.com/asadm/playroom-docs/blob/main/examples/drawinggame/src/AvatarBar/AvatarIcon.jsx
import {PlayerState} from "playroomkit"
import repoDefaultImage from "./assets/default-avatar.png"
import {cn} from "./utils/css"

type AvatarIconProps = {
  player: PlayerState
  size: number
  className?: string
  style?: Partial<CSSStyleDeclaration>
  noDefault?: boolean
  defaultImage?: string
}
export function AvatarIcon({
  player,
  size,
  className = "",
  style,
  noDefault,
  defaultImage,
}: AvatarIconProps) {
  const profile = player?.getProfile()
  style ??= {}
  style.width = `${size}px`
  style.height = `${size}px`
  if (profile) style["borderColor"] = profile.color.hexString
  if (profile && profile.photo) {
    style["backgroundImage"] = `url(${profile.photo})`
    style["backgroundSize"] = "contain"
  } else if (!noDefault) {
    if (defaultImage === "color" && profile) {
      style["background"] = profile.color.hexString
    } else {
      style["backgroundImage"] = `url(${defaultImage || repoDefaultImage})`
    }
    style["backgroundSize"] = defaultImage ? "cover" : "contain"
  }
  return <div className={cn("inline-block", className)} style={style as any}></div>
}
