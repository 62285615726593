import {isFinalRound, hostSetScreen, scoresAtom} from "../game"
import {HostContinueButton} from "../components/components"
import {ordinalName} from "../../shared/utils/builtins"
import {twScreenTitle} from "../components/styles"
import {AvatarIcon} from "../AvatarIcon"
import {getPlayer} from "../players"
import {cn} from "../utils/css"
import {Submission} from "../components/Submission"

type VoteResult = {playerId: string; voterIds: string[]}
type VoteResultsScreenProps = {
  voteResults: VoteResult[]
  theme: string
  round: number
}

export function VoteResultsScreen({voteResults, theme, round}: VoteResultsScreenProps) {
  const twHeaderItem = "py-[5px] px-2 border-r last:border-r-0 border-yellow-800"
  const iconSize = 28
  return (
    <div>
      <div className={cn(twScreenTitle, "italic")}>{theme}</div>
      {voteResults.map((result, index) => {
        const voteCount = result.voterIds.length
        const voteSuffix = voteCount === 0 ? "s" : voteCount === 1 ? ":" : "s:"
        const rank = index + 1
        const placePrefix = rank === 1 ? "🏆 " : ""
        const placeText = placePrefix + ordinalName(rank)
        const player = getPlayer(result.playerId)
        return (
          <div key={result.playerId}>
            <Submission
              round={round}
              playerId={result.playerId}
              header={
                <div className="flex bg-yellow-200 border-b border-yellow-800 rounded-t-md whitespace-nowrap">
                  <div className={cn(twHeaderItem, "text-xl flex items-center")}>{placeText}</div>
                  <div className={cn(twHeaderItem, "flex items-center gap-1.5")}>
                    <AvatarIcon size={iconSize} player={player} />
                    <div>{player.getProfile().name}</div>
                  </div>
                  <div className={cn(twHeaderItem, "flex items-center overflow-hidden")}>
                    {voteCount} vote{voteSuffix}
                    <div className="flex items-center px-1 gap-px">
                      {result.voterIds.map((voterId) => (
                        <AvatarIcon key={voterId} player={getPlayer(voterId)} size={iconSize} />
                      ))}
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        )
      })}
      <HostContinueButton
        onClick={() => {
          const scores = updateScores(voteResults, round)
          hostSetScreen("ScoreScreen", {scores, round})
        }}
      />
    </div>
  )
}

function updateScores(voteResults: VoteResult[], round: number) {
  const multiplier = isFinalRound(round) ? 2 : 1
  const scores = {...scoresAtom.get()}
  let first = true
  for (const voteResult of voteResults) {
    let score = scores[voteResult.playerId] ?? 0
    score += voteResult.voterIds.length * 10 * multiplier
    if (first) {
      score += 5 * multiplier
      first = false
    }
    scores[voteResult.playerId] = score
  }
  scoresAtom.hostSet(scores)
  return scores
}
