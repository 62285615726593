import {usePlayerAtom} from "../atoms"
import {imageAtom, loadImage, submissionAtom} from "../game"
import {GeneratedImage} from "../client"
import {getPlayer} from "../players"
import {ReactNode, Suspense, Thenable, use} from "react"
import {ErrorBoundary} from "react-error-boundary"
import robotDanceGif from "../assets/robot-dance.gif"
import {PromiseWithStatus} from "../utils/PromiseWithStatus"
import {cn} from "../utils/css"

export type SubmissionSharedProps = {
  header?: ReactNode
  onClick?: () => void
}

export type SubmissionProps = SubmissionSharedProps & {
  playerId: string
  round: number
}

export function Submission({playerId, header, round, onClick}: SubmissionProps) {
  const player = getPlayer(playerId)
  // NB: usePlayerState doesn't properly handle dynamic player, so this only works if this
  // component or one of its parents is keyed by playerId.
  const [submissionInput] = usePlayerAtom(player, submissionAtom(round))
  const [imageState] = usePlayerAtom(player, imageAtom(round))
  const text = submissionInput?.text ?? "Loading..."
  const image = new PromiseWithStatus<GeneratedImage>((resolve, reject) => {
    if (!imageState) return
    if ("error" in imageState) reject(imageState.error)
    else resolve(imageState)
  }).ignoreUnhandledRejection()

  return <SubmissionCore header={header} text={text} image={image} onClick={onClick} />
}

export type SubmissionCoreProps = SubmissionSharedProps & {
  text: string
  image: Thenable<GeneratedImage>
}

export function SubmissionCore({text, image, header, onClick}: SubmissionCoreProps) {
  return (
    <div
      className={cn("border border-yellow-800 rounded-md mb-5", onClick && "cursor-pointer")}
      onClick={onClick}
    >
      {header}
      <div className="py-1.5 px-1.5 bg-yellow-100 rounded-t-md border-b border-yellow-800">
        {text}
      </div>
      <div className="aspect-square overflow-hidden justify-center items-center flex rounded-b-md">
        <ErrorBoundary
          fallbackRender={({error}) => (
            <div className="size-full justify-center items-center flex">{`${error}`}</div>
          )}
        >
          <Suspense fallback={<ImageLoading />}>
            <SubmissionImage image={image} />
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  )
}

function ImageLoading() {
  return (
    <div className="text-center">
      <img className="size-[288px]" src={robotDanceGif} />
      <div className="-mt-6 pb-6 text-lg sm:text-base">Generating image...</div>
    </div>
  )
}

function SubmissionImage({image}: {image: Thenable<GeneratedImage>}) {
  // Wait for api response containing image url
  const {url, enhancedPrompt} = use(image)
  // Wait for image to load (so we continue to show suspense until it's ready)
  use(loadImage(url))
  return (
    <img className="size-full" src={url} alt={enhancedPrompt ?? ""} title={enhancedPrompt ?? ""} />
  )
}
