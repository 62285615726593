import {isHost} from "playroomkit"
import {useTimeout} from "../react/hooks"
import {InterstitialName, InterstitialProps, interstitials} from "./interstitials"
import {hostSetScreen, ScreenName, ScreenProps} from "../game"

type InterstitialScreenProps<T extends ScreenName> = {
  nextScreenName: T
  nextScreenProps: ScreenProps<T>
  name: InterstitialName
}
export function InterstitialScreen<T extends ScreenName>({
  nextScreenName,
  nextScreenProps,
  name,
}: InterstitialScreenProps<T>) {
  const {title, content, duration = 2500} = interstitials[name] as InterstitialProps

  function finish() {
    if (isHost()) hostSetScreen(nextScreenName, nextScreenProps)
  }

  useTimeout(duration, finish)

  return (
    <div
      className="bg-black flex flex-col items-center justify-center absolute inset-0 text-white gap-2 text-center"
      onClick={finish}
    >
      <h1 className="text-4xl">{title}</h1>
      <div className="text-xl">{content}</div>
    </div>
  )
}
