import {useIsHost} from "playroomkit"
import {useResetLayout} from "../react/hooks"
import {cn} from "../utils/css"
import {twButton} from "./styles"

export function WaitingForOthers() {
  useResetLayout([])
  return <div className="font-bold text-center">Waiting for other players...</div>
}
export function HostButton({label, onClick}: {label: string; onClick: () => void}) {
  const isHost = useIsHost()
  if (!isHost) return null
  return (
    <button className={cn(twButton, "mb-4")} onClick={onClick}>
      {label}
    </button>
  )
}
export function HostContinueButton({onClick}: {onClick: () => void}) {
  return <HostButton label="Continue" onClick={onClick} />
}
