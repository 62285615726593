import {useEffectOnce, useResetLayout} from "./react/hooks"
import {initGame, screenAtom} from "./game"
import {InterstitialScreen} from "./screens/InterstitialScreen"
import {ScoreScreen} from "./screens/ScoreScreen"
import {ShowcaseScreen} from "./screens/ShowcaseScreen"
import {ThemeScreen} from "./screens/ThemeScreen"
import {VoteResultsScreen} from "./screens/VoteResultsScreen"
import {VoteScreen} from "./screens/VoteScreen"
import {WriteScreen} from "./screens/WriteScreen"
import {useGameAtom} from "./atoms"
import {twRootLayout} from "./components/styles"
import {Start} from "./Start"
import {useHash} from "./react/useHash"
import {Playground} from "./Playground"

export type ScreenName = keyof typeof screens
export type ScreenProps<T extends ScreenName> = Parameters<(typeof screens)[T]>[0]
// This should only be used in this file. Only the types above should need to be exported,
// otherwise you end up with circular dependencies that break HMR.
const screens = {
  ThemeScreen,
  WriteScreen,
  ShowcaseScreen,
  VoteScreen,
  VoteResultsScreen,
  ScoreScreen,
  InterstitialScreen,
} as const

export function Game() {
  const [hash] = useHash(() => {
    // Have to reload to get playroom to switch to the room of the new hash.
    // If the old hash is empty, we're starting the game for first time and don't need to reload.
    if (hash !== "") location.reload()
  })

  // Must be in an effect because playroom changes state outside this component
  useEffectOnce(initGame)
  const [screenState] = useGameAtom(screenAtom)
  useResetLayout([screenState])
  // screenState will be undefined until the playroom lobby finishes. The empty background will only
  // show for about 1 second until the lobby loads on top of it.
  if (!screenState) return <div className="absolute inset-0 bg-black playroom-bg"></div>

  const [name, props] = screenState
  const Screen = screens[name]

  // console.log("Rendering screen", Screen, name, props)

  return (
    <div className={twRootLayout}>
      <Screen {...(props as any)} />
    </div>
  )
}
