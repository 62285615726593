import {WaitingForOthers} from "../components/components"
import {twScreenTitle} from "../components/styles"

import {myPlayer} from "playroomkit"
import {useEffect} from "react"
import {AvatarIcon} from "../AvatarIcon"
import {getPlayer} from "../players"
import {hostOnAllPlayersAtomTruthy} from "../utils/playroom"
import {cn} from "../utils/css"
import {gameIdAtom, hostSetScreen, imageAtom} from "../game"
import {Submission} from "../components/Submission"
import {playerRoundAtom, useMyPlayerAtom} from "../atoms"
import {rpc} from "../client"

const voteAtom = playerRoundAtom<string>("vote")

export type VoteScreenProps = {submittedIds: string[]; theme: string; round: number}
export function VoteScreen({submittedIds, theme, round}: VoteScreenProps) {
  const [vote, setVote] = useMyPlayerAtom(voteAtom(round))

  useEffect(() => {
    return hostOnAllPlayersAtomTruthy(voteAtom(round), {bots: false}, (playerStates) => {
      const voterIdsByVotee = new Map<string, string[]>()
      for (const playerState of playerStates) {
        const votee = playerState.state
        const voterIds = voterIdsByVotee.get(votee) ?? []
        voterIds.push(playerState.player.id)
        voterIdsByVotee.set(votee, voterIds)
      }
      const voteResults = submittedIds.map((playerId) => ({
        playerId,
        voterIds: voterIdsByVotee.get(playerId) ?? [],
      }))
      voteResults.sort((a, b) => b.voterIds.length - a.voterIds.length)

      const gameId = gameIdAtom.get()
      if (gameId) {
        const imageVoteCounts: Record<string, number> = Object.create(null)
        const roundImageAtom = imageAtom(round)
        for (const playerId of submittedIds) {
          const player = getPlayer(playerId)
          const imageResult = roundImageAtom.get(player)
          if (imageResult && "id" in imageResult) {
            const numVotes = voterIdsByVotee.get(playerId)?.length ?? 0
            // No need to record anything for a bot w/ 0 votes (but for humans we do, see below)
            if (player.isBot() && numVotes === 0) continue
            // We add 1 here to account for the player "voting" for their own image when they
            // chose among the multiple preview images in WriteScreen.
            imageVoteCounts[imageResult.id] = numVotes + 1
          }
        }
        rpc.vote.mutate({gameId, votes: imageVoteCounts})
      }

      hostSetScreen("VoteResultsScreen", {voteResults, theme, round})
    })
  }, [submittedIds, theme, round])

  if (vote) {
    const votee = getPlayer(vote)
    return (
      <div>
        <div className="text-center mt-3 mb-10">
          <div className="mb-2">You voted for</div>
          <AvatarIcon size={64} player={votee} />
          <div className="font-medium italic">{votee.getProfile().name}</div>
        </div>
        <WaitingForOthers />
      </div>
    )
  }

  // console.log({submittedIds, theme, round})
  // if (submittedIds == null) {
  //   debugger
  // }
  const myId = myPlayer().id
  const shouldFilterMyId = submittedIds.length > 2
  return (
    <div>
      <div className={cn(twScreenTitle, "italic")}>{theme}</div>
      {submittedIds.map((playerId) => {
        if (shouldFilterMyId && playerId === myId) return null
        return (
          <Submission
            key={playerId}
            playerId={playerId}
            round={round}
            onClick={() => {
              setVote(playerId)
            }}
          />
        )
      })}
    </div>
  )
}
