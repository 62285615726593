import {createBrowserRouter} from "react-router-dom"
import {Start} from "./Start.tsx"
import {Playground} from "./Playground.tsx"
import {Game} from "./App.tsx"
import {Layout} from "./Layout.tsx"
import {RouterError} from "./RouterError.tsx"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <RouterError />,
    children: [
      {
        index: true,
        element: <Start />,
      },
      {
        path: "/playground",
        element: <Playground />,
      },
      {
        path: "/play",
        element: <Game />,
      },
    ],
  },
])
