import {twButton, twLinkButton, twRootLayout} from "./components/styles"
import robotDanceGif from "./assets/robot-dance.gif"
import {cn} from "./utils/css"
import {Link} from "react-router-dom"

export function Start() {
  return (
    <div className={twRootLayout}>
      <h1 className="text-center text-5xl font-bold font-mono" style={{fontFamily: "Courier New"}}>
        AI PARTY
      </h1>
      <img src={robotDanceGif} />
      <Link to="/play" className={cn(twLinkButton, "mb-1.5")}>
        Create game
      </Link>
      <button
        className={twButton}
        onClick={() => {
          alert("Ask the host to click the Invite button and share the link")
        }}
      >
        Join game
      </button>
    </div>
  )
}
