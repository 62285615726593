import {PlayerState, isHost, onPlayerJoin, waitForPlayerState} from "playroomkit"
import {PlayerAtom} from "../atoms"

type PlayerStateWithValue<T = unknown> = {
  player: PlayerState
  state: T
}

export function onEachPlayerAtomTruthy<T>(
  atom: PlayerAtom<T | undefined>,
  {bots, humans = true}: {bots: boolean; humans?: boolean},
  onAtomTruthy: (player: PlayerState, state: T) => void,
) {
  if (!bots && !humans) throw new Error("Either bots or humans must be true")
  let cancelled = false
  const cleanup = onPlayerJoin((player) => {
    if (cancelled) return // Don't remove, this can still get called after cleanup
    const isBot = player.isBot()
    if ((isBot && !bots) || (!isBot && !humans)) return
    waitForPlayerState(player, atom.key, (state: T) => {
      // We still want to run this after the effect has been cancelled so that, e.g., some state syncing to a non-host player after the screen has changed (because the host already had all the state) can still get processed (e.g., preloading something for a future screen)
      onAtomTruthy(player, state)
    })
  })
  return () => {
    cancelled = true
    cleanup()
  }
}

export function hostOnAllPlayersAtomTruthy<T>(
  atom: PlayerAtom<T | undefined>,
  {bots, humans = true}: {bots: boolean; humans?: boolean},
  onAllDoneHost: (playerStates: PlayerStateWithValue<T>[]) => void,
  onPlayerDone?: (player: PlayerState, state: T) => void,
) {
  if (!bots && !humans) throw new Error("Either bots or humans must be true")
  const notDone = new Set<string>()
  let cancelled = false
  const playerStates: PlayerStateWithValue<T>[] = []
  const cleanups = [
    onPlayerJoin((player) => {
      if (cancelled) return // Don't remove, this can still get called after cleanup
      const isBot = player.isBot()
      if ((isBot && !bots) || (!isBot && !humans)) return
      notDone.add(player.id)
      const onDone = () => {
        if (cancelled) return
        notDone.delete(player.id)
        if (isHost() && notDone.size === 0) {
          cancelled = true
          onAllDoneHost(playerStates)
        }
      }
      waitForPlayerState(player, atom.key, (state: T) => {
        // We still want to run this after the effect has been cancelled so that, e.g., some state syncing to a non-host player after the screen has changed (because the host already had all the state) can still get processed (e.g., preloading something for a future screen)
        onPlayerDone?.(player, state)
        if (cancelled) return
        playerStates.push({player, state})
        onDone()
      })
      cleanups.push(player.onQuit(onDone))
    }),
  ]
  return () => {
    cancelled = true
    cleanups.forEach((cleanup) => cleanup())
  }
}
