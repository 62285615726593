import {useEffect, useMemo, useRef} from "react"
import * as Sentry from "@sentry/react"
import * as R from "remeda"
import {twButton} from "../components/styles"
import {twScreenTitle} from "../components/styles"
import {AvatarIcon} from "../AvatarIcon"
import {getPlayer} from "../players"
import {useTimeLeft} from "../react/hooks"
import {themes} from "../themes"
import {cn} from "../utils/css"
import {useIsHost, myPlayer, usePlayersList} from "playroomkit"
import {pastThemesAtom, hostSetScreen, anySetScreen, isDevMode} from "../game"

export function ThemeWaitScreen({themeChooser}: {themeChooser: string}) {
  const tcPlayer = getPlayer(themeChooser)
  return (
    <div className="flex flex-col items-center justify-center absolute inset-0 text-xl gap-1">
      <div className="flex items-center gap-1 justify-center">
        <AvatarIcon size={28} player={tcPlayer} />
        <div>{tcPlayer.getProfile().name}</div>
      </div>
      <div>is choosing a prompt...</div>
    </div>
  )
}
export function ThemeChooserScreen({round, pastThemes}: {round: number; pastThemes: string[]}) {
  const themeChoices = useMemo(() => {
    const pastThemesSet = new Set(pastThemes)
    const unusedThemes = themes.filter((theme) => !pastThemesSet.has(theme))
    return R.sample(unusedThemes, 5)
  }, [pastThemes])
  const inputRef = useRef<HTMLInputElement>(null)
  const timeLeft = useTimeLeft(15, 1000, (timeLeft) => {
    if (timeLeft <= 0) {
      submitTheme(R.sample(themeChoices, 1)[0]!)
    }
  })

  // set the tag immediately on new round
  useMemo(() => Sentry.setTag("round", round), [round])

  const submitTheme = (theme: string) => {
    pastThemesAtom.anySet([...pastThemes, theme])
    anySetScreen("WriteScreen", {theme, round})
  }

  return (
    <div>
      <div className="font-bold pb-1 text-center">{Math.ceil(timeLeft)}</div>
      <div className={twScreenTitle}>Choose a prompt:</div>
      <div>
        {themeChoices.map((theme, i) => (
          <button className={cn(twButton, "mb-1")} key={i} onClick={() => submitTheme(theme)}>
            {theme}
          </button>
        ))}
        {isDevMode() && (
          <>
            <div className="text-center mt-5 mb-2">Or write your own:</div>
            <form
              className="flex gap-1"
              action="#" // Needed for correct "Go" label on iPhone keyboard
              onSubmit={(event) => {
                event.preventDefault()
                submitTheme(inputRef.current!.value.trim())
              }}
            >
              <input className="border border-yellow-800 w-full p-2 rounded-lg" ref={inputRef} />
              <button className={cn(twButton, "w-28")}>Submit</button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}
export function ThemeScreen({
  round,
  themeChooser,
  pastThemes,
}: {
  round: number
  themeChooser: string
  pastThemes: string[]
}) {
  const isHost = useIsHost()
  const myId = myPlayer().id
  const isThemeChooser = myId === themeChooser
  const playersList = usePlayersList()
  // If themeChooser quits, change it to the host
  if (isHost && playersList.findIndex((player) => player.id === themeChooser) === -1) {
    hostSetScreen("ThemeScreen", {round, themeChooser: myId, pastThemes})
  }

  return isThemeChooser ? (
    <ThemeChooserScreen round={round} pastThemes={pastThemes} />
  ) : (
    <ThemeWaitScreen themeChooser={themeChooser} />
  )
}
