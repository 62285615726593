import {gameRoundAtom, useGameAtom} from "../atoms"
import {Submission} from "../components/Submission"
import {HostContinueButton} from "../components/components"
import {twScreenTitle} from "../components/styles"
import {cn} from "../utils/css"
import {hostSetInterstitial} from "./interstitials"

const showcaseIndexAtom = gameRoundAtom("showcaseIndex", 0)

export type ShowcaseScreenProps = {submittedIds: string[]; theme: string; round: number}
export function ShowcaseScreen({submittedIds, theme, round}: ShowcaseScreenProps) {
  const [showcaseIndex, setShowcaseIndex] = useGameAtom(showcaseIndexAtom(round))
  const playerId = submittedIds[showcaseIndex]!

  return (
    <div>
      <div className={cn(twScreenTitle, "italic")}>{theme}</div>
      {/* key is needed because usePlayerState doesn't properly handle dynamic playerId */}
      <Submission key={playerId} round={round} playerId={playerId} />
      <HostContinueButton
        onClick={() => {
          const done = showcaseIndex >= submittedIds.length - 1

          if (done) {
            hostSetInterstitial("vote", "VoteScreen", {submittedIds, theme, round})
          } else {
            setShowcaseIndex(showcaseIndex + 1)
          }
        }}
      />
    </div>
  )
}
