import {PlayerState, onPlayerJoin} from "playroomkit"
import {mustGet} from "../shared/utils/builtins"

// Includes players who have quit, so we can use submissions of former players
export const curPlayersById = new Map<string, PlayerState>()
export const quitPlayersById = new Map<string, PlayerState>()

export function getHumans() {
  return [...curPlayersById.values()].filter((player) => !player.isBot())
}

export function getBots() {
  return [...curPlayersById.values()].filter((player) => player.isBot())
}

export function getPlayer(id: string) {
  return curPlayersById.get(id) ?? mustGet(quitPlayersById, id)
}

export function initPlayers() {
  onPlayerJoin((player) => {
    console.log("player joined", player)
    const existingPlayer = curPlayersById.get(player.id)
    // When starting a game, playroom calls onPlayerJoin twice for the host
    if (existingPlayer) {
      if (existingPlayer !== player) throw Error(`Got different player with same id: ${player.id}`)
      return
    }
    quitPlayersById.delete(player.id)
    curPlayersById.set(player.id, player)
    player.onQuit(() => {
      console.log("player quit", player)
      curPlayersById.delete(player.id)
      quitPlayersById.set(player.id, player)
    })
  })
}
